import React, { useEffect, useReducer, useState } from "react";
import { useHistory } from "react-router-dom";

import openSocket from "../../services/socket-io";

import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { DeleteOutline, Edit } from "@material-ui/icons";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import BotModal from "../../components/BotModal";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ConfirmationModal";
import QueueSelect from "../../components/QueueSelect";


const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_BOTS") {
    const bots = action.payload;
    const newBots = [];

    bots.forEach((bot) => {
      const botIndex = state.findIndex((q) => q.id === bot.id);
      if (botIndex !== -1) {
        state[botIndex] = bot;
      } else {
        newBots.push(bot);
      }
    });

    return [...state, ...newBots];
  }

  if (action.type === "UPDATE_BOTS") {
    const bots = action.payload;
    const botsIndex = state.findIndex((u) => u.id === bots.id);

    if (botsIndex !== -1) {
      state[botsIndex] = bots;
      return [...state];
    } else {
      return [bots, ...state];
    }
  }

  if (action.type === "DELETE_BOTS") {
    const botId = action.payload;
    const botsIndex = state.findIndex((q) => q.id === botId);
    if (botsIndex !== -1) {
      state.splice(botsIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const Bots = () => {
  const classes = useStyles();
  const history = useHistory();

  const [bots, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);

  const [botsModalOpen, setBotsModalOpen] = useState(false);
  const [selectedQueue, setSelectedQueue] = useState(null);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/bots");
        dispatch({ type: "LOAD_BOTS", payload: data });

        setLoading(false);
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const socket = openSocket();

    socket.on("bots", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_BOTS", payload: data.bots });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_BOTS", payload: data.botId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenQueueModal = () => {
    setBotsModalOpen(true);
    setSelectedQueue(null);
  };

  const handleCloseQueueModal = () => {
    setBotsModalOpen(false);
    setSelectedQueue(null);
  };

  const handleEditQueue = (bots) => {
    setSelectedQueue(bots);
    setBotsModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedQueue(null);
  };

  const handleDeleteQueue = async (botId) => {
    try {
      await api.delete(`/bots/${botId}`);
      toast.success(i18n.t("Bots deleted successfully!"));
    } catch (err) {
      toastError(err);
    }
    setSelectedQueue(null);
  };

  const handleOpenBotDetails = (botId) => {
  history.push(`/botsquerry?id=${botId}`);
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          selectedQueue &&
          `${i18n.t("bots.confirmationModal.deleteTitle")} ${
            selectedQueue.name
          }?`
        }
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeleteQueue(selectedQueue.id)}
      >
        {i18n.t("bots.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <BotModal
        open={botsModalOpen}
        onClose={handleCloseQueueModal}
        botId={selectedQueue?.id}
      />
      <MainHeader>
        <Title>{i18n.t("bots.title")}</Title>
        <MainHeaderButtonsWrapper>
          
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenQueueModal}
          >
            {i18n.t("bots.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("bots.table.name")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("bots.table.greeting")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("bots.table.types")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("bots.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {bots.map((bot) => (
                <TableRow key={bot.id}>
                  <TableCell align="center">{bot.name}</TableCell>
                  <TableCell align="center">
                    <div className={classes.customTableCell}>
                      <Typography
                        style={{ width: 300, textAlign: "center" }}
                        noWrap
                        variant="body2"
                      >
                        {bot.greetingMessage}
                      </Typography>
                    </div>
                  </TableCell> 
                  <TableCell align="center">{bot.types}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleOpenBotDetails(bot.id)}
                    >
                      <SettingsOutlinedIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleEditQueue(bot)}
                    >
                      <Edit />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={() => {
                        setSelectedQueue(bot);
                        setConfirmModalOpen(true);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ///
              ))}
              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Bots;
